import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import colors from '../config/color';
import RingHeader from '../components/ringHeader';
import StoreButton from '../components/storeButton';
import IntroPrice from '../components/introPrice';
import HereHearIntroList from '../components/herehearIntroList';

class Homepage extends Component {
  componentDidMount() {
    window.location.href = 'https://herehear.com.tw';
  }

  render() {
    return null;
    return (
      <Layout showHeader={false} showHero={false}>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />
        {/* <div className="search-bar-up">
          <BigSearchBar categories={data.allWordpressCategory.edges} />
          <Product title={''} edges={data.allWordpressPost.edges} />
          <div className="more-btn-content">
            <Link style={{ textAlign: 'center' }} to={'/posts/page/1'}>
              <div className="more">看更多</div>
            </Link>
          </div>
          <Category edges={data.allWordpressCategory.edges} />
        </div> */}

        <div className="container" style={{ backgroundSize: '100% 1000px' }}>
          <RingHeader
            title="APP"
            textStyle={{ color: '#363636', borderColor: '#363636' }}
          />
          <div className="content">
            <div className="title-content">
              <div>
                <h1>Here Hear</h1>
                <h3>I am Feeling Better</h3>
                <h4>專為情緒困擾設計的 APP</h4>
                <div className="button-content">
                  <div className="sty">
                    <StoreButton
                      onClick={() => {
                        window.location.href =
                          'https://apps.apple.com/tw/app/herehear-%E8%81%BD%E8%81%BD/id1479546405';
                      }}
                      text="Apple Store"
                    />
                  </div>
                  <StoreButton
                    onClick={() => {
                      window.location.href =
                        'https://play.google.com/store/apps/details?id=com.Bamboodd.HereHearV2&hl=zh_TW';
                    }}
                    text="Google Play"
                  />
                </div>
              </div>
              <img src="/images/mockup.png"></img>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p className="text-content">
                Here Hear
                是一個專業的情緒管理系統，結合敘事治療、正念冥想、音樂治療等心理學理論的開發應用，以全匿名、可變聲的方式讓你毫無顧慮的說出自己的故事，同時也聽見別人的故事，引導你從中找到每個故事對你的意義與價值，產生療癒作用；配合情緒釋放分析的功能讓你追蹤自己的情緒變化，從而改善情緒困擾的問題。
              </p>
            </div>
            <div
              className="pc"
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'cneter',
              }}
            >
              <div>
                <img
                  className="layout-pc"
                  style={{ marginTop: 100 }}
                  src="/layout/w1.png"
                ></img>
              </div>
              <div>
                <img className="layout-pc" src="/layout/w2.png"></img>
              </div>
            </div>
            <div
              className="mobile"
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'cneter',
              }}
            >
              <div>
                <img className="layout" src="/layout/h1.png"></img>
              </div>
              <div>
                <img className="layout" src="/layout/h2.png"></img>
              </div>
              <div>
                <img className="layout" src="/layout/h3.png"></img>
              </div>
              <div>
                <img className="layout" src="/layout/h4.png"></img>
              </div>
              <div>
                <img className="layout" src="/layout/h5.png"></img>
              </div>
            </div>
            <div className="intro-price-content">
              <IntroPrice />
            </div>
            {/* <HereHearIntroList /> */}
          </div>
        </div>

        <style jsx>{`
          .container {
            background-image: url('/images/main.jpg');
            background-repeat: no-repeat;
            width: 100%;
            height: 1200px;
            text-align: center;
            position: relative;
            z-index: 0;
            min-width: 375px;
          }

          .content {
            padding: 0 0;
          }

          .title-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          h1 {
            color: ${colors['purple']};
            font-family: NotoSansCJKtc-Bold;
            font-size: 48px;
            line-height: 44px;
            margin: 60px 0 10px 0;
          }

          h3 {
            color: ${colors['black-p']};
            font-family: NotoSansCJKtc-Medium;
            font-size: 24px;
            line-height: 44px;
            margin-bottom: 30px;
          }

          h4 {
            color: ${colors['black-p']};
            font-family: NotoSansCJKtc-Medium;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 15px;
          }

          .sty {
            margin-right: 10px;
          }

          .button-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
          }

          img {
            margin-bottom: 30px;
            width: 320px;
          }

          .text-content {
            color: ${colors['black-p']};
            font-family: NotoSansCJKtc-Regular;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 20px;
            text-align: left;
            max-width: 360px;
          }

          .layout {
            width: 100%;
          }

          .mobile {
            display: flex;
          }
          .pc {
            display: none;
          }

          @media (min-width: 768px) {
            .layout {
              width: 50%;
            }

            .mobile {
              display: none;
            }
            .pc {
              display: flex;
            }

            .layout-pc {
              width: 100%;
              max-width: 1000px;
            }

            .container {
              background-size: cover;
            }

            .content {
              padding: 0 60px;
            }

            .title-content {
              flex-direction: row;
            }

            h1 {
              font-size: 60px;
              margin: 0px 0 35px 0;
            }

            h3 {
              font-size: 48px;
              margin-bottom: 50px;
            }

            h4 {
              font-size: 21px;
              margin-bottom: 35px;
            }

            img {
              margin-top: 45px;
              margin-bottom: 55px;
            }

            .sty {
              margin-right: 20px;
            }

            .text-content {
              max-width: 1000px;
              font-size: 18px;
              line-height: 27px;
              margin-bottom: 20px;
            }

            .intro-price-content {
              max-width: 1200px;
              margin: auto;
            }
          }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20) {
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
  }
`;
