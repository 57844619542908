import React from 'react';
import PriceInfoCard from '../components/priceInfoCard';
import colors from '../config/color';

const IntroPrice = () => {
  const onPress = () => {
    window.location.href = 'https://beta-api.herehear.com.tw/api/v2/payment';
  };
  return (
    <div className="content">
      <h2>比較 Here Hear 方案</h2>
      <div className="price-card">
        <PriceInfoCard onPress={onPress} text="一個月" price="490" />
        <PriceInfoCard onPress={onPress} text="雙人" price="900" />
        <PriceInfoCard onPress={onPress} text="一年期" price="4,990" />
      </div>

      <style jsx>{`
        .content {
          height: 100%;
          background-color: ${colors['slime-green']};
          text-align: center;
          padding: 70px 0 100px 0;
        }

        h2 {
          color: ${colors['black']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 36px;
          line-height: 60px;
          margin: 0 16px;
        }

        .price-card {
          display: flex;
          flex-direction: row;
          margin: 25px 16px 0 16px;
        }

        @media (min-width: 768px) {
          .content {
            padding: 130px 0 140px 0;
          }

          h2 {
            font-size: 48px;
            margin: 0 60px;
          }

          .price-card {
            margin: 90px 45px 0 45px;
          }
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

IntroPrice.propTypes = {};

IntroPrice.defaultProps = {};

export default IntroPrice;
