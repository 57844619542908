import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import colors from '../config/color';

const ShoppingButton = props => {
  const { text, btnStyle } = props;
  return (
    <a href style={btnStyle} className="button-content">
      {text}

      <style jsx>{`
        .button-content {
          color: ${colors['purple']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 16px;
          line-height: 1.5;
          border: 1px ${colors['purple']} solid;
          border-radius: 10px;
          padding: 7px 18px;
          display: inline-flex;
        }

        .button-content:hover {
          color: ${colors['white']};
          background-color: ${colors['purple']};
          cursor: pointer;
        }

        

        @media (min-width: 768px) {
          .button-content {
            font-size: 18px;
            padding: 8px 34px;
        }

        @media (min-width: 1200px) {
          }
      `}</style>
    </a>
  );
};

ShoppingButton.propTypes = {
  text: PropTypes.string,
  btnStyle: PropTypes.object,
};

ShoppingButton.defaultProps = {
  text: '',
  btnStyle: {},
};

export default ShoppingButton;
