import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import colors from '../config/color';

const StoreButton = props => {
  const { text, btnStyle, onClick } = props;
  return (
    <button style={btnStyle} onClick={onClick} className="button-content">
      <div>{text}</div>

      <style jsx>{`
        .button-content {
          color: ${colors['black-p']};
          background-color: ${colors['white']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 14px;
          line-height: 26px;
          border-radius: 50px;
          padding: 8px 29px;
          display: inline-flex;
        }

        .button-content:hover {
          cursor: pointer;
        }

        @media (min-width: 768px) {
          .button-content {
            font-size: 24px;
            line-height: 36px;
            padding: 16px 40px;
        }

        @media (min-width: 1200px) {
          }
      `}</style>
    </button>
  );
};

StoreButton.propTypes = {
  text: PropTypes.string,
  btnStyle: PropTypes.object,
  onClick: PropTypes.func,
};

StoreButton.defaultProps = {
  text: '',
  btnStyle: {},
  onClick: () => {},
};

export default StoreButton;
